import PropTypes from 'prop-types';
import textStyles from 'config/branding/textStyles';
import Link from '@activebrands/core-web/components/Link';
import media from '@activebrands/core-web/config/media';
import RichText from '@activebrands/core-web/libs/storyblok/RichText';
import { styled, useStyletron } from '@activebrands/core-web/libs/styletron';
import responsiveTextStyles from '@activebrands/core-web/utils/responsive-text-styles';
import ArrowPagination from 'components/slider/ArrowPagination';
import Heading from 'components/text/Heading';
import Tag from 'components/text/Tag';
import ThemeButton from './buttons/ThemeButton';

const Wrapper = styled('div', ({ $isSlider, $type }) => ({
    width: '100%',
    height: '100%',
    marginBottom: '24px',
    padding: '0 var(--margin-content)',

    [media.min['mobile.lg']]:
        $type === 'product'
            ? $isSlider
                ? {
                      height: 'auto',
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                  }
                : {
                      display: 'flex',
                      justifyContent: 'space-between',
                  }
            : {
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
              },

    [media.min['desktop.sm']]: {
        width: '100%',
        height: 'auto',
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'row',
        marginBottom: '24px',
    },
}));

const ButtonWrapper = styled('div', ({ $type }) => ({
    display: $type === 'highlight' ? 'block' : 'none',

    [media.min['mobile.lg']]:
        $type === 'product'
            ? {
                  gridColumn: '7 / span 6',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                  height: '100%',
              }
            : {},
    [media.min['desktop.sm']]:
        $type === 'article' || $type === 'feature'
            ? {
                  gridColumn: '7 / span 6',
                  display: 'flex',
                  flexDirection: 'row',
              }
            : {},
}));

const PromotionalHeader = ({
    $paginationStyle,
    $style,
    button,
    heading,
    headingFontKeys,
    headingType,
    tagFontKeys,
    paragraphFontKeys,
    slider,
    tag,
    text,
    type,
}) => {
    const [css] = useStyletron();

    return (
        <Wrapper $style={$style} $type={type} $isSlider={!!slider}>
            <div className={css({ width: ['100%', null, '50%'] })}>
                {tag && <Tag $style={{ marginBottom: '24px', ...responsiveTextStyles(tagFontKeys) }}>{tag}</Tag>}
                {heading && (
                    <Heading $style={{ marginBottom: '16px' }} as={headingType} fontKeys={headingFontKeys}>
                        {heading}
                    </Heading>
                )}
                <div className={css({ ...textStyles[paragraphFontKeys], width: '100%' })}>
                    <RichText data={text} />
                </div>
            </div>
            <div className={css({ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' })}>
                <ButtonWrapper $type={type}>
                    {button?.label && button?.url && (
                        <ThemeButton
                            fontKeys="Misc/16_100_-02"
                            $style={{
                                marginLeft: slider ? '0' : ['auto', null, null, null, null, '0'],
                                width: slider ? '100%' : [null, null, null, null, null, '100%'],
                                height: '40px',
                                padding: '12px',
                                marginTop: 'auto',
                            }}
                            as={Link}
                            rel={button.rel}
                            theme={button.theme}
                            to={button.url}
                        >
                            {button.label}
                        </ThemeButton>
                    )}

                    {slider && (
                        <ArrowPagination
                            $style={{ marginTop: 'auto', width: '40px', height: '40px', ...$paginationStyle }}
                            slider={slider}
                        />
                    )}
                </ButtonWrapper>
            </div>
        </Wrapper>
    );
};

PromotionalHeader.propTypes = {
    $paginationStyle: PropTypes.object,
    $style: PropTypes.object,
    button: PropTypes.object,
    heading: PropTypes.string,
    headingFontKeys: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    headingType: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
    paragraphFontKeys: PropTypes.string,
    slider: PropTypes.object,
    tag: PropTypes.string,
    tagFontKeys: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    text: PropTypes.string,
    type: PropTypes.string,
};

export default PromotionalHeader;

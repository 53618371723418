import PropTypes from 'prop-types';
import Price from '@activebrands/core-web/components/Price';
import media from '@activebrands/core-web/config/media';
import { styled, useStyletron } from '@activebrands/core-web/libs/styletron';
import responsiveTextStyles from '@activebrands/core-web/utils/responsive-text-styles';
import ColorSwatches from 'components/product/ColorSwatches';
import Heading from 'components/text/Heading';
import { Link } from 'gatsby';

const Wrapper = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '8px 0',
    color: 'var(--color-text-product-card)',

    [media.min['desktop.sm']]: {
        padding: '8px 0px',
        flexDirection: 'row',
        flexWrap: 'wrap',
    },
});

const GridCardBottomSection = ({
    $style = {},
    currentVariationId = '',
    name = '',
    price = {},
    relatedVariations = [],
    uri = '',
}) => {
    const [css] = useStyletron();

    return (
        <Wrapper $style={$style}>
            <div
                className={css({
                    display: ['block', null, null, null, null, 'flex'],
                    flexDirection: 'column',
                    gap: '12px',
                    marginBottom: ['8px', null, null, null, null, '0'],
                    width: ['100%', null, '70%'],
                })}
            >
                <Link
                    className={css({
                        alignSelf: 'start',
                        width: 'auto',
                    })}
                    title={name}
                    to={uri}
                >
                    <Heading fontKeys={['Misc/14_110_500_-0175', null, null, null, null, 'Misc/16_120_500_-02']}>
                        {name}
                    </Heading>
                </Link>
                {relatedVariations.length > 1 && (
                    <ColorSwatches
                        $style={{
                            width: '100%',
                            display: ['none', null, null, null, null, 'flex'],
                            justifyContent: 'start',
                            paddingLeft: '2px', // needs padding because rotated
                        }}
                        currentVariationId={currentVariationId}
                        relatedVariations={relatedVariations}
                        textStyles="Misc/10_100_-00625"
                    />
                )}
            </div>
            <Price
                $style={{
                    marginBottom: ['12px', null, null, null, null, '0'],
                    display: [null, null, null, null, null, 'flex'],
                    flexDirection: 'column',
                    alignItems: 'flex-end',
                    textAlign: ['left', null, null, null, null, 'right'],
                    lineHeight: '140%',
                    width: ['100%', null, '30%'],
                }}
                activePrice={price?.salePriceWithCurrency}
                fontStyling={{
                    ...responsiveTextStyles(['Misc/12_100_-015', null, null, null, null, 'Misc/16_100_-02']),
                }}
                originalPrice={price?.priceWithCurrency}
            />
            {relatedVariations.length > 1 && (
                <ColorSwatches
                    $style={{
                        width: '100%',
                        display: ['flex', null, null, null, null, 'none'],
                        justifyContent: 'start',
                        paddingLeft: '2px', // needs padding because rotated
                    }}
                    currentVariationId={currentVariationId}
                    relatedVariations={relatedVariations}
                    textStyles="Misc/10_100_-00625"
                />
            )}
        </Wrapper>
    );
};

GridCardBottomSection.propTypes = {
    $style: PropTypes.object,
    currentVariationId: PropTypes.string,
    name: PropTypes.string,
    price: PropTypes.object,
    relatedVariations: PropTypes.array,
    uri: PropTypes.string,
};

export default GridCardBottomSection;

import PropTypes from 'prop-types';
import SVG from '@activebrands/core-web/components/SVG';

const BasketIcon = ({ $style, color = 'currentColor', width = '16px', height = '16px', ...rest }) => {
    const style = { width, height, ...$style };
    return (
        <SVG $style={style} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <path d="M1.5 15.5V2.5H14.5V15.5H1.5Z" stroke={color} />
            <path d="M5.5 3V0.5H10.5V3" stroke={color} />
        </SVG>
    );
};

BasketIcon.propTypes = {
    $style: PropTypes.object,
    color: PropTypes.string,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
};

export default BasketIcon;

import { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import textStyles from 'config/branding/textStyles';
import useSiteSelectorQuery from 'queries/useSiteSelectorQuery';
import Image from '@activebrands/core-web/components/Image';
import media from '@activebrands/core-web/config/media';
import RichText from '@activebrands/core-web/libs/storyblok/RichText';
import { styled, useStyletron } from '@activebrands/core-web/libs/styletron';
import { setSiteSelectorCookie } from '@activebrands/core-web/utils/handle-site-selector-cookie';
import Button from 'components/buttons/Button';
import Checkbox from 'components/inputs/CheckboxInput';
import RadioInput from 'components/inputs/RadioInput';
import Heading from 'components/text/Heading';
import Paragraph from 'components/text/Paragraph';

const Wrapper = styled('div', ({ $isOpen }) => ({
    position: 'absolute',
    top: '0',
    bottom: '0',
    width: '100%',
    padding: `calc(var(--height-header) + 16px) 12px 0 12px`,
    backgroundColor: 'var(--color-bg-navigation)',
    transform: $isOpen ? 'translate3d(0, 0, 0)' : 'translate3d(-120%, 0, 0)',
    transition: 'transform var(--transition-fast)',
    overflow: 'scroll',
    zIndex: 1,
    paddingBottom: '124px', // Prevents the last option to be hidden behind the toggle element

    // Hide scrollbar in safari and chrome
    '::-webkit-scrollbar': {
        display: 'none',
    },

    // Hide scrollbar in firefox
    scrollbarWidth: 'none',

    [media.min['desktop.sm']]: {
        padding: '40px 24px 0 24px',
        width: 'calc(var(--width-overlays) - 20px)',
    },
}));

const SiteSelector = ({ $style, alternateHrefs = [], isOverlay, isSiteSelectorOpen }) => {
    const [css] = useStyletron();

    const {
        siteSelectorExternalHeading,
        siteSelectorExternalHeadingType,
        siteSelectorExternalLinks,
        siteSelectorExternalParagraph,
        siteSelectorNavigationHeading,
        siteSelectorNavigationHeadingType,
        siteSelectorLinks,
        siteSelectorParagraph,
        siteSelectorRedirectLabel,
    } = useSiteSelectorQuery();

    const currentSiteId = useSelector(state => state.application.aliasId);
    const [isAlwaysRedirected, setIsAlwaysRedirected] = useState(true);

    const handleChange = () => {
        setIsAlwaysRedirected(!isAlwaysRedirected);
    };

    return (
        <Wrapper $isOpen={isOverlay ? true : isSiteSelectorOpen} $style={$style}>
            {siteSelectorLinks?.length && (
                <div className={css({ display: 'flex', flexDirection: 'column' })}>
                    {siteSelectorNavigationHeading && (
                        <Heading as={siteSelectorNavigationHeadingType} fontKeys="Primary/24_-015">
                            {siteSelectorNavigationHeading}
                        </Heading>
                    )}
                    {siteSelectorParagraph?.content?.length && (
                        <div className={css({ marginTop: '16px', ...textStyles['Secondary/14_130_-0175'] })}>
                            <RichText data={siteSelectorParagraph} />
                        </div>
                    )}
                    {siteSelectorRedirectLabel && (
                        <div className={css({ marginTop: '16px', ...textStyles['Misc/12_100_-015'] })}>
                            <Checkbox
                                checked={isAlwaysRedirected}
                                label={siteSelectorRedirectLabel}
                                onChange={handleChange}
                            />
                        </div>
                    )}

                    <div className={css({ display: 'flex', flexDirection: 'column', marginTop: '16px' })}>
                        {siteSelectorLinks.map((site, index) => (
                            <Button
                                $style={{
                                    width: '100%',
                                    padding: '16px 0 8px 0',
                                    border: 'none',
                                    borderBottom: '1px solid var(--color-border-button-default)',
                                }}
                                as="a"
                                href={alternateHrefs.find(href => href.aliasId === Number(site.id))?.uri || site.path}
                                key={`${site.countryCode}-${index}`}
                                onClick={() => (!!isAlwaysRedirected ? setSiteSelectorCookie(site.path) : null)}
                            >
                                <div
                                    className={css({
                                        display: 'flex',
                                        alignItems: 'center',
                                        height: 'auto',
                                        alignSelf: 'center',
                                        gridColumn: '1 / span 2',
                                    })}
                                >
                                    {site.icon && (
                                        <div
                                            className={css({ marginRight: '8px', width: '24px', height: '16px' })}
                                            src={site.icon}
                                        >
                                            <Image sizes={['24px']} src={{ url: site?.icon, width: [24, 48, 72] }} />
                                        </div>
                                    )}
                                    <Paragraph className={css({ textAlign: 'left' })} fontKeys="Misc/16_100_-02">
                                        {site.label}
                                    </Paragraph>
                                    <RadioInput
                                        readOnly
                                        $style={{
                                            position: 'fixed',
                                            right: ['4px', null, null, null, null, '16px'], //margin due to labels default margin in RadioInput
                                        }}
                                        checked={site.id === currentSiteId}
                                    />
                                </div>
                            </Button>
                        ))}
                    </div>
                </div>
            )}
            {siteSelectorExternalLinks?.length ? (
                <div className={css({ display: 'flex', flexDirection: 'column', marginTop: '64px' })}>
                    {siteSelectorExternalHeading && (
                        <Heading as={siteSelectorExternalHeadingType} fontKeys="Primary/24_-015">
                            {siteSelectorExternalHeading}
                        </Heading>
                    )}
                    {siteSelectorExternalParagraph?.content?.length && (
                        <div className={css({ marginTop: '16px', ...textStyles['Secondary/14_130_-0175'] })}>
                            <RichText data={siteSelectorExternalParagraph} />
                        </div>
                    )}

                    <div className={css({ display: 'flex', flexDirection: 'column' })}>
                        {siteSelectorExternalLinks.map((site, index) => (
                            <Button
                                $style={{
                                    width: '100%',
                                    padding: '16px 0 8px 0',
                                    border: 'none',
                                    borderBottom: '1px solid var(--color-border-button-default)',
                                }}
                                as="a"
                                href={site.path}
                                key={`${site.countryCode}-${index}`}
                                onClick={() => (!!isAlwaysRedirected ? setSiteSelectorCookie(site.path) : null)}
                            >
                                <div
                                    className={css({
                                        display: 'flex',
                                        alignItems: 'center',
                                        height: 'auto',
                                        alignSelf: 'center',
                                        gridColumn: '1 / span 2',
                                    })}
                                >
                                    {site.icon && (
                                        <div
                                            className={css({ marginRight: '8px', width: '24px', height: '16px' })}
                                            src={site.icon}
                                        >
                                            <Image sizes={['24px']} src={{ url: site?.icon, width: [24, 48, 72] }} />
                                        </div>
                                    )}
                                    <Paragraph className={css({ textAlign: 'left' })} fontKeys="Misc/16_100_-02">
                                        {site.label}
                                    </Paragraph>
                                </div>
                            </Button>
                        ))}
                    </div>
                </div>
            ) : null}
        </Wrapper>
    );
};

SiteSelector.propTypes = {
    $style: PropTypes.object,
    alternateHrefs: PropTypes.arrayOf(
        PropTypes.exact({
            aliasId: PropTypes.number,
            app: PropTypes.string,
            appId: PropTypes.number,
            hrefLang: PropTypes.string,
            locale: PropTypes.string,
            uri: PropTypes.string,
        })
    ),
    isOverlay: PropTypes.bool,
    isSiteSelectorOpen: PropTypes.bool,
};

export default SiteSelector;

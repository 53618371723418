import PropTypes from 'prop-types';
import SVG from '@activebrands/core-web/components/SVG';

const CloseIcon = ({ $style = {}, color = 'currentColor', viewBox = '0 0 12 13', size = '12px', ...rest }) => {
    const style = { width: size, height: size, ...$style };

    return (
        <SVG $style={style} viewBox={viewBox} fill="none" {...rest}>
            <path stroke={color} d="m11 1.5-10 10M11 11.5 1 1.5" />
        </SVG>
    );
};

CloseIcon.propTypes = {
    $style: PropTypes.object,
    color: PropTypes.string,
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    viewBox: PropTypes.string,
};

export default CloseIcon;

import PropTypes from 'prop-types';
import Link from '@activebrands/core-web/components/Link';
import ColumnsLayout from '@activebrands/core-web/components/content-layouts/ColumnsLayout';
import useReInitWidgets from '@activebrands/core-web/libs/lipscore/hooks/useReInitWidgets';
import { useStyletron } from '@activebrands/core-web/libs/styletron';
import { useLocation } from '@reach/router';
import ArticleCard from 'components/ArticleCard';
import PromotionalHeader from 'components/PromotionalHeader';
import ThemeButton from 'components/buttons/ThemeButton';
import ProductCard from 'components/product/ProductCard';

const PromotionGrid = ({
    $style = {},
    button = {},
    columnSizes,
    heading,
    headingFontKeys,
    headingType,
    id,
    imageSettings,
    items,
    paragraphFontKeys,
    tag,
    text,
    type,
}) => {
    const [css] = useStyletron();
    const location = useLocation();

    useReInitWidgets(location.href);

    return (
        <div className={css($style)}>
            <PromotionalHeader
                $paginationStyle={{ display: 'none' }}
                $style={{ display: 'flex' }}
                button={button}
                heading={heading}
                headingFontKeys={headingFontKeys}
                paragraphFontKeys={paragraphFontKeys}
                headingType={headingType}
                id={id}
                tag={tag}
                text={text}
                type={type}
            />
            <ColumnsLayout
                $style={{ display: ['none', null, null, null, 'flex'], padding: '0 var(--margin-content)' }}
                columnSizes={columnSizes}
                gutterHeight={40}
                gutterWidth={['12', null, null, null, '16']}
                items={items.map((item, i) => {
                    if (type === 'product') {
                        return (
                            <ProductCard
                                {...item}
                                media={item.media?.length > 0 ? item.media.slice(0, 1) : []}
                                imageSettings={imageSettings}
                                key={item.id}
                                loading="lazy"
                                position={i}
                            />
                        );
                    }
                    if (type === 'article') {
                        return (
                            <ArticleCard
                                {...item}
                                imageSettings={imageSettings}
                                key={item.id}
                                loading="lazy"
                                position={i}
                            />
                        );
                    }

                    return null;
                })}
            />
            <ColumnsLayout
                $style={{ display: ['flex', null, null, null, 'none'], padding: '0 var(--margin-content)' }}
                columnSizes={columnSizes}
                gutterHeight={40}
                gutterWidth={['12', null, null, null, '16']}
                id={id}
                items={items.map((item, i) => {
                    if (type === 'product') {
                        return (
                            <ProductCard
                                {...item}
                                media={item.media?.length > 0 ? item.media.slice(0, 1) : []}
                                imageSettings={imageSettings}
                                key={item.id}
                                loading="lazy"
                                position={i}
                            />
                        );
                    }
                    if (type === 'article') {
                        return (
                            <ArticleCard
                                {...item}
                                imageSettings={imageSettings}
                                key={item.id}
                                loading="lazy"
                                position={i}
                            />
                        );
                    }

                    return null;
                })}
            />
            {button.label && button.url && (
                <div className={css({ width: '100%', padding: '0 var(--margin-content)' })}>
                    <ThemeButton
                        $style={{
                            marginTop: '24px',
                            width: ['100%', null, 'auto'],
                            display: ['flex', null, 'none'],
                        }}
                        as={Link}
                        rel={button.rel}
                        theme={button.theme}
                        to={button.url}
                    >
                        {button.label}
                    </ThemeButton>
                </div>
            )}
        </div>
    );
};
PromotionGrid.propTypes = {
    $style: PropTypes.object,
    button: PropTypes.object,
    columnSizes: PropTypes.array,
    heading: PropTypes.string,
    headingFontKeys: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    headingType: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
    id: PropTypes.string,
    imageSettings: PropTypes.shape({
        width: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.number)]),
        sizes: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    }).isRequired,
    items: PropTypes.array,
    paragraphFontKeys: PropTypes.object,
    tag: PropTypes.string,
    text: PropTypes.string,
    type: PropTypes.oneOf(['product', 'article']),
};

export default PromotionGrid;

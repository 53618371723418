import PropTypes from 'prop-types';
import SVG from '@activebrands/core-web/components/SVG';

const PlusIcon = ({ $style = {}, color = 'currentColor', viewBox = '0 0 12 12', size = '12px', ...rest }) => {
    const style = { width: size, height: size, ...$style };

    return (
        <SVG $style={style} viewBox={viewBox} fill="none" {...rest}>
            <path stroke={color} d="M6 0v12M12 6H0" />
        </SVG>
    );
};

PlusIcon.propTypes = {
    $style: PropTypes.object,
    color: PropTypes.string,
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    viewBox: PropTypes.string,
};

export default PlusIcon;

import PropTypes from 'prop-types';
import SVG from '@activebrands/core-web/components/SVG';

const SearchIcon = ({ $style = {}, color = 'currentColor', height = '16px', width = '16px', ...rest }) => {
    const style = { width, height, ...$style };

    return (
        <SVG $style={style} viewBox="0 0 12 12" fill="none" {...rest}>
            <g clipPath="url(#clip0_16221_6654)">
                <path d="M8.72656 8.72461L12.002 12" stroke={color} />
                <circle cx="5.35156" cy="5.25" r="4.75" stroke={color} />
            </g>
            <defs>
                <clipPath id="clip0_16221_6654">
                    <rect width="12" height="12" fill="none" />
                </clipPath>
            </defs>
        </SVG>
    );
};

SearchIcon.propTypes = {
    $style: PropTypes.object,
    color: PropTypes.string,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
};

export default SearchIcon;
